<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="agent_data"
      :items-per-page="10"
      @click:row="rowClick"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="65vh"
      fixed-header
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
          <AddData />
          <v-btn rounded color="info ml-2" dark @click="printSection">
            <v-icon left>
              mdi-printer
            </v-icon>
            พิมพ์
          </v-btn>
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ agent_count }}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'blue lighten-5': item.id === selectedId }"
          class="font-weight-black"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td :class="id_column">{{ item.id }}</td>
          <!-- <td>{{ item.Nickname }}</td> -->
          <td v-if="item.Status == 0" class="red--text">{{ item.Nickname }}</td>
          <td v-if="item.Status == 1">{{ item.Nickname }}</td>

          <td>{{ item.Password }}</td>
          <td>{{ item.PhoneNumber1 }}</td>
          <td>{{ item.LineAccount }}</td>

          <td v-if="item.Status == 0" class="red--text">ระงับ</td>
          <td v-if="item.Status == 1" class="green--text">ปกติ</td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import PopupMenu from "@/components/Cus/Line/Genaral/Popup_Menu";
import AddData from "@/components/Cus/Line/Genaral/Add_Data";

export default {
  name: "Line_Datatable",

  components: {
    PopupMenu,
    AddData,
  },
  created() {
    //โหลด ตาราง
    this.LineID = this.$route.query.line_id;
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    agent_data() {
      return this.$store.getters["agent/Agents"];
    },
    agent_count() {
      return this.$store.getters["agent/Agents"].length;
    },
  },
  methods: {
    //   SetLineID(LineID){
    //       this.LineID = LineID;
    //   },
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        ListType: "genaral_list",
      };
      await this.$store.dispatch("agent/get_list", credentials).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selectedId = item.id;
      console.log(item.id);
    },
    row_click: function(item) {
      this.selectedId = item.id;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();

      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(item.id);
        this.selectedId = item.id;
        //console.log(item);
      });
    },
    printSection() {
      this.$htmlToPaper("printSection");
    },
  },
  data: () => ({
    LineID: "",
    AgentID: "",

    selectedId: -1,

    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,

    headers: [
      {
        text: "ใบที่",
        value: "PageNumber",
        width: "20px",
      },
      { text: "ยอดรวม", value: "BetSum", align: "start", width: "80px" },
      {
        text: "ยอดลอย",
        value: "BetLoy",
        align: "start",
        width: "40px",
        sortable: false,
      },
      {
        text: "ถูกรวม",
        value: "WinSum",
        align: "start",
        width: "60px",
      },
      { text: "ถูกลอย", value: "WinLoy", align: "start", width: "50px" },
      { text: "สถานะ", value: "Status", align: "start", width: "80px" },
    ],
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: rgb(57, 137, 198) !important;
  color: white;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: rgb(57, 137, 198) !important;
  color: white !important;
  padding-top: 15px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  /* font-size: 1.0vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 14px !important;
  /* font-size: 110% !important; */
}

::v-deep table tbody tr td {
  /* font-size: 1.5vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 14px !important;
  /* font-size: 110% !important; */
}
/* /deep/ tr.v-data-table__selected {
    background: lightgray !important;
    color: black;
    font-weight: bold;
  } */
</style>
