<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <v-list dense>
        <v-list-item link @click="menuActionClick('cus')">
          <v-list-item-icon>
            <v-icon color="success">mdi-face-man-profile</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>คนขาย</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link>
          <v-list-item-icon>
            <v-icon color="warning">mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>รายละเอียด</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link>
          <v-list-item-icon>
            <v-icon color="blue">mdi-lead-pencil</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>แก้ไข</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item link @click="deleteItem()">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ลบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PopupMenu",

  methods: {
    Show(id) {
      this.showMenu = true;
      this.id = id;
    },
    Hide(x, y) {
      this.x = x;
      this.y = y;
    },
    menuActionClick(menu) {
      switch (menu) {
        case "cus":
          //add session
          //save host_id
          //sessionStorage.setItem("agent_id", this.id);
          //goto agent page

          this.$router.push({
            path: "agent_data",
            query: { line_id: this.id },
          });
          //this.$router.push("/Agent_Data");
          break;
      }
    },
  },
  data: () => ({
    id: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),
};
</script>

<style></style>
