<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :hide-overlay="$vuetify.breakpoint.mdAndDown"
    persistent
    max-width="600px"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded color="warning" dark v-bind="attrs" v-on="on">
        <v-icon left>
          mdi-plus-outline
        </v-icon>
        เพิ่ม
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>AAA</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step :complete="e1 > 1" step="1">
            ข้อมูลทั่วไป
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2">
            อัตราจ่ายและเปอร์เซ็นต์
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <AddDetail ref="cAddDetail" />

            <!-- <v-btn
          color="primary"
          @click="e1 = 2"
        >
          Continue
        </v-btn>

        <v-btn text>
          Cancel
        </v-btn> -->
          </v-stepper-content>

          <v-stepper-content step="2">
            <AddPercent />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import AddDetail from "@/components/Cus/Line/Genaral/Add_Detail";
import AddPercent from "@/components/Cus/Line/Genaral/Add_Percent";

export default {
  name: "Add_Data",

  components: {
    AddDetail,
    AddPercent,
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.cAddDetail.OnShow();
          //   this.id_search = "";
          //   this.firstname_search = "";
          //   this.lastname_search = "";
          //   this.selectedId = -1;
          //   this.$store.getters["cus/CusList"].splice(0);
          //   this.$refs.txt_id_search.focus();
        });
      }
    },
  },
  methods: {
    submitData() {
      alert("submit");
    },
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    //stepper
    e1: 1,
    // form
    valid: true,

    form: {
      id: "",
      name: "",
      pw: "",
      tel: "",
      credit: 0,
      line_account: "",
      address: "",
      status: "",
      note: "",
    },

    rules: {
      id: [(val) => (val || "").length > 0 || "This field is required"],
      name: [
        (v) => !!v || "ต้องกรอกชื่อ",
        (v) => (v && v.length <= 50) || "ชื่อใส่ได้ไม่เกิน 50 ตัวอักษร",
      ],
      pw: [
        (v) => !!v || "ต้องใส่รหัสผ่าน",
        (v) => (v && v.length <= 12) || "รหัสผ่านใส่ไมไ่ด้เกิน 25 ตัวอักษร",
      ],
      tel: [
        (v) => (v && v.length <= 25) || "เบอร์โทรใส่ไมไ่ด้เกิน 25 ตัวอักษร",
      ],
      line_account: [
        (v) => (v && v.length <= 100) || "ไลน์ใส่ไมไ่ด้เกิน 100 ตัวอักษร",
      ],
    },

    //select
    id_list: ["01", "02", "03", "04", "05"],
  }),
};
</script>

<style scoped></style>
