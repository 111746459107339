<template>
            <!-- start form -->
      <v-card class="px-4">
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title class="info--text">อัตราจ่าย เปอร์เซนต์</v-card-title>
            <v-card-subtitle class="info--text"
              >ใช้สำหรับตั้งค่าเริ่มต้นเวลาเพิ่มคนขายใหม่</v-card-subtitle
            >
            <v-row>
              <v-col cols="3" class="text-xs-center"> </v-col>
              <v-col cols="3" class="text-xs-center">
                %สาย
              </v-col>
              <v-col cols="3" class="text-xs-center">
                %คนขาย
              </v-col>
              <v-col cols="3" class="text-xs-center">
                อัตราจ่าย
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="3 ตัวตรง"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="3 ตัวโต็ด"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="2 ตัวบน"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="2 ตัวล่าง"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="คู่โต๊ดเล็ก"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="พวง"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="หมุน 4 ครั้ง"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="จม"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="ลอยบน"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="3 pa-0 ma-0" class="">
                <v-text-field
                  color="pink"
                  value="ลอยล่าง"
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="indigo lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="yellow lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3 pa-0 ma-0">
                <v-text-field
                  color="pink"
                  background-color="green lighten-5"
                  type="number"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="submitData">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>

</template>

<script>
export default {
  name: "Add_Percent",
  methods: {
    submitData() {
      alert("submit");
    },
  },
  data: () => ({

    // form
    valid: true,

    form: {

    },

    rules: {
    //   id: [(val) => (val || "").length > 0 || "This field is required"],
    //   name: [
    //     (v) => !!v || "ต้องกรอกชื่อ",
    //     (v) => (v && v.length <= 50) || "ชื่อใส่ได้ไม่เกิน 50 ตัวอักษร",
    //   ],
    //   pw: [
    //     (v) => !!v || "ต้องใส่รหัสผ่าน",
    //     (v) => (v && v.length <= 12) || "รหัสผ่านใส่ไมไ่ด้เกิน 25 ตัวอักษร",
    //   ],
    //   tel: [
    //     (v) => (v && v.length <= 25) || "เบอร์โทรใส่ไมไ่ด้เกิน 25 ตัวอักษร",
    //   ],
    //   line_account: [
    //     (v) => (v && v.length <= 100) || "ไลน์ใส่ไมไ่ด้เกิน 100 ตัวอักษร",
    //   ],
    },

    //select
    // id_list: ["01", "02", "03", "04", "05"],
  }),
}
</script>

<style>

</style>