<template>
  <!-- start form -->
  <v-card class="px-0">
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title class="info--text">ข้อมูลติดต่อ</v-card-title>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="form.id"
              :items="id_list"
              :rules="rules.id"
              color="pink"
              label="รหัส"
              required
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.pw"
              :counter="12"
              :rules="rules.pw"
              color="pink"
              label="พาส"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.name"
              :counter="50"
              :rules="rules.name"
              color="pink"
              label="ชื่อ"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.tel"
              :counter="25"
              :rules="rules.tel"
              color="pink"
              label="เบอร์โทร"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.line_account"
              :counter="100"
              :rules="rules.line_account"
              color="pink"
              label="ไลน์"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="form.address"
              color="pink"
              label="ที่อยู่"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-title class="info--text">ตั้งค่า</v-card-title>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.credit"
              :rules="rules.credit"
              color="pink"
              label="เครดิต"
              required
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.status"
              :counter="10"
              :rules="rules.status"
              color="pink"
              label="สถานะ"
              required
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-title class="info--text">บันทึก</v-card-title>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="form.note"
              label="โน๊ต"
              auto-grow
              color="pink"
              rows="3"
              row-height="25"
              dense
            ></v-textarea>
            <!-- :class="{'dense': $vuetify.breakpoint.smAndDown}" -->
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="dialog = false">
        ยกเลิก
      </v-btn>
      <v-btn color="blue darken-1" text @click="submitData">
        บันทึก
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Add_Detail",
  methods: {
    OnShow() {
      alert("Show");
    },
    submitData() {
      alert("submit");
    },
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    //stepper
    e1: 1,
    // form
    valid: true,

    form: {
      LineID: "",
      Password: "",
      Nickname: "",
      PhoneNumber1: "",
      CurCredit: 0,
      LineAccount: "",
      Address: "",
      Status: "",
      Note: "",
    },

    rules: {
      id: [(val) => (val || "").length > 0 || "This field is required"],
      name: [
        (v) => !!v || "ต้องกรอกชื่อ",
        (v) => (v && v.length <= 50) || "ชื่อใส่ได้ไม่เกิน 50 ตัวอักษร",
      ],
      pw: [
        (v) => !!v || "ต้องใส่รหัสผ่าน",
        (v) => (v && v.length <= 12) || "รหัสผ่านใส่ไมไ่ด้เกิน 25 ตัวอักษร",
      ],
      tel: [
        (v) => (v && v.length <= 25) || "เบอร์โทรใส่ไมไ่ด้เกิน 25 ตัวอักษร",
      ],
      line_account: [
        (v) => (v && v.length <= 100) || "ไลน์ใส่ไมไ่ด้เกิน 100 ตัวอักษร",
      ],
    },

    //select
    //id_list: ["01", "02", "03", "04", "05"],
  }),

  computed: {
    id_list() {
      let id = [];
      for (let i = 1; i <= 99; i++) {
        id.push(("0" + i).slice(-2));
      }
      for (let key in this.$store.getters["line/Lines"]) {
        let index = id.indexOf(this.$store.getters["line/Lines"][key]["id"]);
        index = ("0" + index).slice(-2);
        id.splice(index, 1);
      }
      return id;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field input {
  font-size: 0.85em !important;
}

::v-deep .v-text-field label {
  font-size: 0.78em !important;
}

::v-deep .v-text-field .v-counter {
  font-size: 0.8em !important;
}

::v-deep .v-text-field .v-messages__message {
  font-size: 0.7em !important;
}
::v-deep .v-text-field .v-select__selection {
  font-size: 0.85em !important;
}

/* ::v-deep .v-text-fields {
    font-size: 8px !important;
} */
</style>
