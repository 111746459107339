<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <v-list dense>
        <v-list-item link @click="menuActionClick('page')">
          <v-list-item-icon>
            <v-icon color="success">mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ดูโพย</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link>
          <v-list-item-icon>
            <v-icon color="warning">mdi-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>รายละเอียด</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="deleteItem()">
          <v-list-item-icon>
            <v-icon color="blue">mdi-email-send</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ส่งโพย</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item link @click="deleteItem()">
          <v-list-item-icon>
            <v-icon color="purple">mdi-arrow-u-down-left-bold</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ตีกลับ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item link @click="deleteItem()">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ลบโพย</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PopupMenu",

  methods: {
    Show(id) {
      this.showMenu = true;
      this.id = id;
    },
    SetAgentID(AgentID) {
      this.AgentID = AgentID;
    },
    SetLineID(LineID) {
      this.LineID = LineID;
    },
    Hide(x, y) {
      this.x = x;
      this.y = y;
    },
    menuActionClick(menu) {
      switch (menu) {
        case "page":
          this.$router.push({
            path: "page",
            query: {
              line_id: this.LineID,
              agent_id: this.AgentID,
              page_number: this.id,
            },
          });

          //this.$router.push("/Agent_Data");
          break;
      }
    },
  },
  data: () => ({
    id: null,
    LineID: null,
    AgentID: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),
};
</script>

<style></style>
