<template>
  <v-dialog
    v-model="dialog"
    :hide-overlay="$vuetify.breakpoint.mdAndDown"
    persistent
    max-width="450px"
    transition="dialog-bottom-transition"
  >
    <!-- :fullscreen="$vuetify.breakpoint.mdAndDown" -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn rounded color="warning" dark v-bind="attrs" v-on="on">
        <v-icon left>
          mdi-plus-outline
        </v-icon>
        เพิ่ม
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">สร้างโพยใหม่</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="จำนวนโพย*"
                ref="txtPageCount"
                min="1"
                type="number"
                :rules="[numberRule]"
                v-model.number="PageCount"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <!-- <small>* ใส่จำนวนโพยที่ต้องการสร้าง</small> -->
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="dialog = false">
          ยกเลิก
        </v-btn>
        <v-btn color="info darken-1" text @click="Create()">
          สร้าง
        </v-btn>
        <v-btn color="success darken-1" text @click="CreateThenEnter()">
          สร้างและเข้าโพย
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Add_Data",

  components: {},

  props: {
    LineID: {
      type: String,
      default: "",
    },
    AgentID: {
      type: String,
      default: "",
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.PageCount = 1;
          this.$refs.txtPageCount.focus();
        });
      }
    },
  },
  methods: {
    CreateThenEnter() {
      if (this.Sending == true) return;
      if (this.PageCount < 1 || this.PageCount > 20) return;
      this.Sending = true;
      this.Mode = 1;
      this.OnNewPage();
    },
    Create() {
      if (this.Sending == true) return;
      if (this.PageCount < 1 || this.PageCount > 20) return;
      this.Sending = true;
      this.Mode = 0;
      this.OnNewPage();
    },

    async OnNewPage() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        CusID: "001",
        NumOfPage: this.PageCount,
      };
      await this.$store.dispatch("pages/new_page", credentials).then(
        (response) => {
          //response.FirstPage
          if (this.Mode == 0) {
            this.$emit("Reload");
          } else {
            this.$emit("EnterPage", response.FirstPage);
          }
          this.Sending = false;
          this.dialog = false;
        },
        (error) => {
          console.log(error);
          switch (error) {
            case "PAGE_FULL":
              this.$alert(
                "หน้ารอส่งสร้างได้สูงสุด 20 หน้าเท่านั้น",
                "หน้ารอส่งเต็ม",
                "error"
              ).then(() => {});
              this.Sending = false;
              break;
            // case "PERIOD_NOT_FOUND":
            //   console.log("ไม่พบงวด");
            //   this.Sending = false;
            //   break;
            default:
              this.Sending = false;
              break;
          }
        }
      );
    },
  },
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,

    // form
    Sending: false,

    PageCount: 1,

    Mode: 0, //0=Reload 1=Enter
    numberRule: (val) => {
      if (val < 1) return "ต้องใส่เลขมากกว่า 0";
      if (val > 20) return "โพยสร้างเกิน 20 ไมไ่ด้";
      return true;
    },
  }),
};
</script>

<style scoped></style>
