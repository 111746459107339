<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="pages_data"
      :items-per-page="10"
      item-key="PageNumber"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="65vh"
      fixed-header
      :dense="$vuetify.breakpoint.mdAndDown"
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat color="grey lighten-5">
          <AddPage
            :LineID="LineID"
            :AgentID="AgentID"
            @Reload="fetch_table"
            @EnterPage="EnterPage"
            v-if="!printing"
          />
          <v-btn rounded color="info ml-2" dark @click="printSection">
            <v-icon left>
              mdi-printer
            </v-icon>
            พิมพ์
          </v-btn>
          <v-divider class="mx-4 info" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn rounded outlined color="cyan">
            <v-icon left>
              mdi-filter-variant
            </v-icon>
            ตัวกรอง
          </v-btn>
        </v-toolbar>
        <v-alert dense text class="text-center" type="success">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ pages_count }}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'green lighten-4': item.PageNumber === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td align="end" :class="id_column">{{ item.PageNumber }}.</td>
          <td align="end">
            {{ add_comma(item.PriceSum) }}
          </td>
          <td align="end">
            {{ add_comma(item.PriceSumLoy) }}
          </td>
          <td align="center" :class="getStatusColor(item.Status)">
            {{ getStatusText(item.Status) }}
          </td>
          <td align="end">
            {{ add_comma(item.WinSum) }}
          </td>
          <td align="end">
            {{ add_comma(item.WinSumLoy) }}
          </td>
          <td align="center">
            {{ item.EditBy }}
          </td>
          <td align="start">
            {{ getDT(item.UpdateDT) }}
          </td>
        </tr>
      </template>

      <template slot="body.prepend">
        <tr>
          <td align="end" :class="id_column_sum">รวม</td>
          <td class="green lighten-2 black--text" align="end">
            {{ add_comma(pages_detail["PriceSum"]) }}
          </td>
          <td class="green lighten-3 black--text" align="end">
            {{ add_comma(pages_detail["PriceSumLoy"]) }}
          </td>
          <td class="green lighten-2 black--text" align="center">
            รอส่ง ({{ add_comma(pages_detail["WaitingSend"]) }})
          </td>
          <td class="green lighten-3 black--text" align="end">
            {{ add_comma(pages_detail["WinSum"]) }}
          </td>
          <td class="green lighten-2 black--text" align="end">
            {{ add_comma(pages_detail["WinSumLoy"]) }}
          </td>

          <td class="green lighten-3" align="center"></td>
          <td class="green lighten-2" align="start"></td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import PopupMenu from "@/components/Cus/Pages/PageList/Popup_Menu";
import AddPage from "@/components/Cus/Pages/PageList/Add_Data";

export default {
  name: "PageList_Datatable",
  mixins: [globalFunctionMixin],

  components: {
    PopupMenu,
    AddPage,
  },
  created() {
    //โหลด ตาราง
    this.LineID = this.$route.query.line_id;
    this.AgentID = this.$route.query.agent_id;
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    pages_data() {
      return this.$store.getters["pages/Pages"];
    },
    pages_count() {
      return this.$store.getters["pages/Pages"].length;
    },
    pages_detail() {
      return this.$store.getters["pages/PagesDetail"];
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        LineID: this.LineID,
        AgentID: this.AgentID,
        ListType: "page_list",
      };
      await this.$store.dispatch("pages/get_list", credentials).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getStatusText(status) {
      const statusText = [
        "ลบ",
        "รอส่ง",
        "ส่งแล้ว",
        "ตีกลับ",
        "ถูกรางวัล",
        "จ่ายแล้ว",
        "",
        "",
        "",
        "",
        "กำลังประมวลผล",
      ];
      return statusText[parseInt(status) + 1];
    },
    getStatusColor(status) {
      const statusColor = [
        "red--text", //ลบ
        "blue-grey--text text--darken-4", //รอส่ง
        "green--text", //ส่งแล้ว
        "red--text", //ตีกลับ
        "deep-purple--text", //ถูกรางวัล
        "black--text", //จ่ายแล้ว
        "black--text",
        "black--text",
        "black--text",
        "black--text",
        "red--text", //กำลังประมวลผล
      ];
      return statusColor[parseInt(status) + 1] + " pl-10";
    },
    EnterPage(PageNumber) {
      this.$router.push({
        path: "page",
        query: {
          line_id: this.LineID,
          agent_id: this.AgentID,
          page_number: PageNumber,
        },
      });
    },
    row_click: function(item) {
      this.selectedId = item.PageNumber;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();
      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.SetAgentID(this.AgentID);
        this.$refs.PopupMenu1.SetLineID(this.LineID);
        this.$refs.PopupMenu1.Show(item.PageNumber);
        this.selectedId = item.PageNumber;
        //console.log(item);
      });
    },
    printSection() {
      this.printing = true;
      console.log(this.printing);
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        //   styles: [
        //     'css/print.css',
        //   ]
      };
      this.$nextTick(() => {
        this.$htmlToPaper("printSection", options, () => {
          this.printing = false;
        });
      });
    },
  },
  data: () => ({
    LineID: "",
    AgentID: "",

    selectedId: -1,

    printing: false,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,

    headers: [
      {
        text: "ใบที่",
        value: "PageNumber",
        width: "1%",
      },
      {
        text: "ยอดรวม",
        value: "BetSum",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "ยอดลอย",
        value: "BetLoy",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
        // sortable: false,
      },
      {
        text: "สถานะ",
        value: "Status",
        align: "center",
        width: "15%",
        class: "brown lighten-5",
      },
      {
        text: "ถูกรวม",
        value: "WinSum",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "ถูกลอย",
        value: "WinLoy",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "แก้ไขโดย",
        value: "Status",
        align: "center",
        width: "15%",
        class: "brown lighten-5",
      },
      {
        text: "อัพเดตล่าสุด",
        value: "Status",
        align: "start",
        width: "29%",
        class: "brown lighten-5",
      },
    ],
    id_column: "id_column_class",
    id_column_sum: "id_column_sum_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: rgb(39, 109, 37) !important;
  color: whitesmoke;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 4%;
  left: 0;
}
::v-deep .id_column_sum_class {
  background-color: rgb(10, 107, 35) !important;
  color: whitesmoke;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 4%;
  left: 0;
}
/* ::v-deep table {
  table-layout : fixed;
} */

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: rgb(39, 109, 37) !important;
  color: whitesmoke !important;
  padding-top: 15px !important;
}

::v-deep table thead th:last-child {
  padding-left: 40px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: rgb(255, 255, 255);
  /* font-size: 1.0vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 18px !important;
  /* font-size: 110% !important; */
}

::v-deep table {
  background-color: #eceff1;
}

::v-deep tr:nth-child(odd) {
  background-color: #edfafc;
}

::v-deep table tbody tr td {
  font-size: 1em !important;
  color: rgb(88, 88, 88);
  font-weight: normal;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 1em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}

/* /deep/ tr.v-data-table__selected {
    background: lightgray !important;
    color: black;
    font-weight: bold;
  } */
</style>
